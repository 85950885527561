// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/products/ProgressBar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/products/ProgressBar.tsx");
  import.meta.hot.lastModified = "1742995935000";
}
// REMIX HMR END

import React, { useEffect, useState } from 'react';
export default function ProgressBar({
  amountInPool,
  filledPools,
  maxValue,
  currentValue,
  color: defaultColor,
  infoTextClassname,
  isSingleOrderPossible,
  baseUnit,
  takeRest,
  takeRestId,
  customerId
}) {
  _s();
  /**
   * 1) Calculate "real" progress based on props
   */
  const progressValue = amountInPool >= maxValue ? 100 : amountInPool % maxValue / maxValue * 100;

  /**
   * 2) We'll track these with local state so we can override them
   *    for the 2-second interval after hitting 100%.
   */
  const [localProgressValue, setLocalProgressValue] = useState(progressValue);
  const [localInfoText, setLocalInfoText] = useState('');
  const [localClassName, setLocalClassName] = useState('');

  /**
   * 3) Figure out the bar color according to the original logic
   */
  let color = defaultColor;
  if (progressValue === 100 && !takeRest) {
    color = 'custom-progress-bar-all-in';
  }
  if (progressValue < 100 && takeRest) {
    color = 'custom-progress-bar-all-in';
  }
  if (progressValue < 100 && !takeRest) {
    color = 'custom-progress-bar-yellow';
  }
  if (progressValue < 100 && !takeRest && isSingleOrderPossible) {
    color = 'custom-progress-bar-all-in';
  }

  /**
   * 4) Determine default info text AND className before we decide on the final local state.
   */
  const remainingValue = amountInPool >= maxValue ? 0 : maxValue - amountInPool % maxValue;
  let infoText = '';
  let barClassName = ''; // <- We'll apply this to the container for "takeRest" logic

  if (amountInPool % maxValue >= maxValue) {
    infoText = 'Bestellmenge erreicht';
  } else if (takeRest) {
    // if "takeRest", then className = "takeRest"
    barClassName = 'takeRest';
    if (takeRestId !== Number(customerId)) {
      infoText = `Rest wird von jemand anderem übernommen (noch ${remainingValue.toLocaleString('de-CH', {
        maximumFractionDigits: 2
      })} ${baseUnit})`;
    } else {
      infoText = `Rest wird übernommen, wenn Menge nicht erreicht wurde (noch ${remainingValue.toLocaleString('de-CH', {
        maximumFractionDigits: 2
      })} ${baseUnit})`;
    }
  } else {
    // normal "Noch X" logic
    if (amountInPool !== 0) {
      const textForSingleOrder = isSingleOrderPossible ? ` ${baseUnit === 'KG' ? 'Kg.' : 'Stk.'}, damit 10% wegfallen` : ` ${baseUnit === 'KG' ? 'Kg.' : 'Stk.'} bis zur ${filledPools + 1}. Bestelleinheit`;
      infoText = `Noch ${Number(remainingValue).toFixed(2)}${textForSingleOrder}`;
    }
  }

  /**
   * 5) Update local state whenever the parent's progress changes:
   *    - If parent's progress hits 100%, we show 100% for 2s, then reset to 0.
   *    - Otherwise, we just follow parent's logic directly.
   */
  useEffect(() => {
    if (progressValue === 100) {
      // Show 100% and "Bestellmenge erreicht" for 2 seconds
      setLocalProgressValue(100);
      setLocalInfoText('Bestellmenge erreicht');
      setLocalClassName(''); // or keep any className you want at 100%

      const timer = setTimeout(() => {
        // After 2s, reset bar + text to the default "Noch ..."
        setLocalProgressValue(0);
        const textForSingleOrder = isSingleOrderPossible ? ` ${baseUnit === 'KG' ? 'Kg.' : 'Stk.'}, damit 10% wegfallen` : ` ${baseUnit === 'KG' ? 'Kg.' : 'Stk.'} bis zur Bestelleinheit`;
        setLocalInfoText(`Noch ${Number(maxValue).toFixed(2)}${textForSingleOrder}`);
        setLocalClassName(barClassName);
      }, 2000);
      return () => clearTimeout(timer);
    } else {
      // If parent's progress < 100, just mirror it
      setLocalProgressValue(progressValue);
      setLocalInfoText(infoText);
      setLocalClassName(barClassName);
    }
  }, [progressValue, infoText, barClassName, amountInPool, maxValue, baseUnit, isSingleOrderPossible]);
  return <div className="w-full">
      <div className="w-full">
        {/*
         <div> Aktueller Stand: {amountInPool}</div>
         */}
        <div data-testid="progress-bar-container" className={`w-full h-4 rounded-full overflow-hidden ${localClassName}`}>
          <div data-testid="progress-bar" className={`h-full ${color} transition-all rounded-full duration-500 ease-in-out`} style={{
          width: `${localProgressValue}%`
        }} />
        </div>
      </div>
      <div className="w-full">
        <div className={`w-full text-sm ${infoTextClassname}`}>
          {localInfoText}.
        </div>
      </div>
    </div>;
}
_s(ProgressBar, "8goESkajvveqNWrsqjrWknOjwLw=");
_c = ProgressBar;
var _c;
$RefreshReg$(_c, "ProgressBar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;